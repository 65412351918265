import * as Types from '../declarations'
import moment from 'moment'
// @ts-ignore
import iso3311a2 from 'iso-country-lookup'

export const lowestNumOfDays = (value: number[]): number => {
	if (value.length === 1) {
		return value[0]
	} else {
		return Math.min(...value)
	}
}

export const sluggify = (text: string): string =>
	text
		.toLowerCase()
		.replace(/[^\w\s-]|_/g, '') // Remove punctuation
		.replace(/[^a-z0-9]+/g, '-') // Replace all non-alphanumeric characters with hyphens
		.replace(/^-+|-+$/g, '') // Remove leading and trailing hyphens if any

export const monthNames = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
]

export const monthNamesFull = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]

// export const extractErrors = (resp: {
// 	errors?: readonly GraphQLError[]
// }): string | undefined => {
// 	if (resp?.errors) {
// 		const errorMessages = resp.errors.map(({ message }) => message)
// 		return errorMessages.join(', ')
// 	}
// 	return undefined
// }

/**/
const omitDeepArrayWalk = (arr: unknown[], key: string): unknown[] => {
	return arr.map((val) => {
		if (Array.isArray(val)) return omitDeepArrayWalk(val, key)
		else if (typeof val === 'object')
			return omitDeep(val as Record<string, unknown>, key)
		return val
	})
}

const omitDeep = (obj: Record<string, unknown>, key: string) => {
	if (obj === undefined || obj === null) {
		// console.log('obj was bad', obj)
		return {}
	}
	const keys = Object.keys(obj)
	const newObj: Record<string, unknown> = {}
	keys.forEach((i) => {
		if (i !== key) {
			const val = obj[i]
			if (val instanceof Date) newObj[i] = val
			else if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key)
			else if (typeof val === 'object' && val !== null)
				newObj[i] = omitDeep(val as Record<string, unknown>, key)
			else newObj[i] = val
		}
	})
	return newObj
}

export const cleanedObject = <T>(myObject: T) =>
	omitDeep((myObject as unknown) as Record<string, T>, '__typename')

export const trekOrClimbDuration = (
	trek: Types.DB.Models.TripAttributes,
): number | undefined => {
	const { trekOrClimb, highLevelInfo } = trek.jsonData
	const isTrekNotPeak = trekOrClimb === 'TREK'

	return isTrekNotPeak
		? highLevelInfo?.distances?.total?.days &&
		  (highLevelInfo?.distances?.total?.days ?? []).length > 0
			? highLevelInfo.distances.total.days[0]
			: undefined
		: highLevelInfo?.climbRoute?.howManyDaysTotal &&
		  (highLevelInfo?.climbRoute?.howManyDaysTotal ?? []).length > 0
		? highLevelInfo?.climbRoute?.howManyDaysTotal[0]
		: undefined
}

export const trekOrClimbMaxHeight = (
	trek: Types.DB.Models.TripAttributes,
): number | undefined => {
	const { trekOrClimb, highLevelInfo } = trek.jsonData
	const isTrekNotPeak = trekOrClimb === 'TREK'

	return isTrekNotPeak
		? highLevelInfo?.distances?.total?.highestPoint
		: highLevelInfo?.climb?.maxElevation
}

export const convertMetricToImperial = (height: number) =>
	Math.ceil(height * 3.28084).toLocaleString()

export const homePageTripToTripCard = (
	homePageTrip: Types.App.HomePageTrip,
): Types.App.TripCardProps => {
	const {
		name,
		trekOrClimb,
		continent,
		countries,
		slug,
		lowestCost,
		totalDistanceLength,
		totalDaysLength,
		height,
		firstUploadId,
		trekDifficulty,
		climbDifficulty,
		isValidCompletePage,
	} = homePageTrip

	return {
		name,
		trekOrClimb,
		continent,
		countries,
		slug,
		lowestCost: lowestCost || undefined,
		totalDistanceLength,
		totalDaysLength,
		height: height || undefined,
		firstUploadId,
		climbDifficulty: !!climbDifficulty
			? (climbDifficulty.map((val) => +val) as [number, number])
			: [],
		trekDifficulty: trekDifficulty || undefined,
		isValidCompletePage,
	}
}

export const tripPageTripToTripCard = (
	tripPageTrip: Types.DB.Models.TripAttributes,
): Types.App.TripCardProps => {
	const {
		name,
		jsonData: {
			trekOrClimb,
			highLevelInfo,
			continent,
			countries,
			climbDifficulty,
			trekDifficulty,
		},
		slug,
		isValidCompletePage,
	} = tripPageTrip

	const lowestCost = (tripPageTrip.guideTrips ?? []).reduce(
		(
			minItem: Types.DB.Models.GuideTripAttributes | undefined,
			currentItem: Types.DB.Models.GuideTripAttributes,
		) => {
			if (minItem === undefined || currentItem.price < minItem.price) {
				return currentItem
			} else {
				return minItem
			}
		},
		undefined,
	)?.price

	const distances = highLevelInfo?.distances?.total.distance ?? undefined

	const totalDaysLength =
		highLevelInfo?.distances?.total?.days ??
		highLevelInfo?.climbRoute?.howManyDaysTotal ??
		undefined

	// ought never to have no uploads - as valid - but just in case, -1 so later that can result in a default graphic
	const firstUploadId = tripPageTrip?.uploads?.[0]?.id ?? -1

	const height = highLevelInfo?.climb?.maxElevation ?? undefined

	return {
		name,
		trekOrClimb,
		continent,
		countries,
		slug,
		lowestCost,
		totalDistanceLength: distances,
		totalDaysLength,
		height,
		firstUploadId,
		trekDifficulty: !!trekDifficulty ? trekDifficulty.average : undefined,
		climbDifficulty: !!climbDifficulty
			? (climbDifficulty.simple as [number] | [number, number])
			: [],
		isValidCompletePage,
	}
}

export const isPublished = (
	isApproved: boolean,
	publicIsValidHoldingPage: boolean,
	publishedFrom: string | null | undefined,
) => {
	if (!isApproved || !publishedFrom || !publicIsValidHoldingPage) {
		return false
	}

	return moment(publishedFrom).isBefore(moment())
}

export const writeToClipboard = (text: string) => {
	navigator.clipboard.writeText(text).catch((err) => {
		console.error('Failed to copy to clipboard: ', err)
	})
}

const simpleDifficultyToFrench: Record<
	number,
	{ abbreviation: string; shortDescription: string }
> = {
	1: { abbreviation: 'F', shortDescription: 'Easy' },
	2: { abbreviation: 'PD', shortDescription: 'Not very hard' },
	3: { abbreviation: 'AD', shortDescription: 'Fairly hard' },
	4: { abbreviation: 'D', shortDescription: 'Hard' },
	5: { abbreviation: 'TD', shortDescription: 'Very hard' },
	6: { abbreviation: 'ED', shortDescription: 'Extremely hard' },
	7: { abbreviation: 'ABO', shortDescription: 'Incredibly hard' },
}
export const convertSimpleToFrench = (
	simple: number,
): { abbreviation: string; shortDescription: string } =>
	simpleDifficultyToFrench[simple]

export const monthNumberToFullName = (number: number): string =>
	monthNamesFull[number - 1]

export const countryNameSlugToIsoCode = (name: string): string => {
	return iso3311a2.getCode(name)?.toLowerCase() ?? ['no country found']
}
export const countryIsoCodeToSlugName = (isoCode: string): string => {
	return sluggify(iso3311a2.getCountry(isoCode.toUpperCase()))
}

export const getFlagURL = (isoCode: string): string => {
	if (['XE', 'XS', 'XW'].includes(isoCode)) {
		const altCode = isoCode
			.replace('XE', 'GB-eng')
			.replace('XS', 'GB-sct')
			.replace('XW', 'GB-wls')
		return `https://purecatamphetamine.github.io/country-flag-icons-other/3x2/${altCode}.svg`
	}
	if (isoCode === 'XZ') {
		return `https://raw.githubusercontent.com/freeciv/freeciv/0695957ae339ec1db15a98e65f8d981ecf16fa0f/data/flags/tibet.svg`
	}

	return `https://purecatamphetamine.github.io/country-flag-icons/3x2/${isoCode}.svg`
}

export const featureFlag = (feature: 'PEAKS_LIMITED'): boolean => {
	if (process.env?.[`FLAG_${feature}`] === 'FALSE') {
		return false
	}
	return true
}
